import type {
  IUseApiCaptchaResponse,
  IUseApiCaptchaReturn,
} from '~/composables/api/captcha/useApiCaptcha.types';
import useApiBase from '~/composables/api/base/useApiBase';

/**
 * @description Composable для работы с функционалом капчи.
 * @param {string} formKey - Название формы.
 * @returns {Promise<IUseApiCaptchaReturn>}
 */
export default async function useApiCaptcha(formKey: string): Promise<IUseApiCaptchaReturn> {
  const { baseApiUrl } = useApiBase();
  const { getGenericCaptchaState } = useIceGenericRequest();

  const captchaImage = ref<string | null>(null);
  const captchaKey = ref<string | null>(null);

  const fetchCaptcha = async (): Promise<void> => {
    if (!getGenericCaptchaState.value) return;

    const response: IUseApiCaptchaResponse = await $fetch(`captcha/form?form=${formKey}`, {
      baseURL: baseApiUrl,
      method: 'GET',
    });

    captchaImage.value = response.image;
    captchaKey.value = response.captcha_key;
  };

  onMounted(async () => await fetchCaptcha());

  return {
    getGenericCaptchaState,
    captchaImage,
    captchaKey,
    fetchCaptcha,
  };
}
